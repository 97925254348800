import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

function Tags({ data, pageContext }) {
  const { allMdx } = data
  const { edges } = allMdx

  return (
    <Layout title={`${pageContext.title}`}>
      <SEO title={pageContext.title} />
      <div>
        <p>
          Hier werden insgesamt <strong>{edges.length}</strong> Blogbeiträge
          aufgelistet die das Tag <strong>{pageContext.title}</strong>{" "}
          enthalten.
        </p>

        <ul>
          {edges.map(edge => {
            const { node } = edge
            const { fields, frontmatter, id } = node

            return (
              <li key={id}>
                <Link to={fields.slug}>{frontmatter.title}</Link>
              </li>
            )
          })}
        </ul>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: Int!) {
    allMdx(
      filter: {
        frontmatter: { categories: { eq: $id } }
        fileAbsolutePath: { regex: "/markdown-pages/bildungsblog/.*.mdx/" }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default Tags
